export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการหวย',
        route: '/manage-lotto',
        icon: 'cil-list',
        items: [
          {
            name: 'วิเคราะห์หวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/result'
          },
          {
            name: 'รายชื่อหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/market'
          },
          {
            name: 'จัดกลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/group'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าเว็บ',
        route: '/setting',
        fontIcon: 'fas fa-sliders-h',
        items: [{
          name: 'SEO',
          fontIcon: 'fas fa-angle-double-right',
          to: '/setting/seo'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการแบนเนอร์',
        route: '/manage-banner',
        fontIcon: 'fas fa-ad',
        items: [{
          name: 'ตำแหน่งหัวเว็บ (B1)',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-banner/b1'
        }, {
          name: 'ตำแหน่งบนเว็บ (B2)',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-banner/b2'
        }, {
          name: 'ตำแหน่งกลางเว็บ (B3)',
          fontIcon: 'fas fa-angle-double-right',
          to: '/manage-banner/b3'
        }]
      }
    ]
  }
]
